/*
Theme Name: ideal2020
Theme URI: https://publy.com
Author: zp
Author URI:
Description: 
Template: storefront
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags:
Text Domain: storefront
*/

@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import "./css/normalize.css";
@import "./css/general.css";
@import "./css/home.css";
@import "./css/hero.css";
@import "./css/page.css";
@import "./css/cart.css";
@import "./css/order-details.css";
@import "./css/map.css";
@import "./css/mapbox-gl.css";
@import "./css/footer.css";



:root {
    /* colore */
    --color_bg : #222;

}