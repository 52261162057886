.page{
	&:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: .6;
    z-index: 0;
		}	
	@media screen and (max-width: 480px){&:before { height: 100vh;}}
	& .first-section:before {
    	top: -70vh;
		height: 70vh;
	}
	& section {
		&.hip{min-height: 100vh; background-size: cover;background-position: center;background-attachment: fixed;background-size:cover;
		
		    @media screen and (max-width: 480px){background-size: auto 98vh;background-repeat: no-repeat;background-attachment: initial;}
		}
		.center_b{width: 80%;
				margin: 0 auto;
				position: absolute;
				z-index: 99;
				display: flex;
				flex-direction: column;
				align-items: center;
			
			& img{}
			& h2{margin-top: 1em;@media screen and (max-width: 480px){text-align:center;}}
		}
		.wp-block-group__inner-container{
			
			& h2{
				&.blockquote{width:70%; margin: 0 auto; text-align:center;
				@media screen and (max-width: 480px){font-size: 1.9em;line-height: 1.2em;width: 85%;margin: 2em auto;}
				}
			}
			& figure{
				&.size-large{@media screen and (max-width: 480px){margin:0; padding:0;}
					& img{width:100%; height: auto;}
				}
			}
		}
	
		& .double-column {
			&:nth-of-type(odd){
				& .wp-block-group__inner-container{& .wp-block-columns{@media screen and (max-width: 480px){flex-direction:column-reverse!important;}}}	
			}
			& .wp-block-group__inner-container{width:80%;margin:0 auto;	
				@media screen and (max-width: 480px){width:100%;margin-bottom: 2em;}
				& .wp-block-columns{display: flex;flex-direction: row;
					@media screen and (max-width: 480px){flex-direction: column;}				
					& .wp-block-column {
						display: flex;
						flex-direction: column;			
						justify-content: center;
						width: 50%;
						overflow: hidden;
						padding: 1em;
						@media screen and (max-width: 480px){width: 100%;padding:0;text-align: center;}
						& figure{
							width: 100%;
							margin: 0;
							padding: 0;
							box-sizing: border-box;
						}
						& h2{@media screen and (max-width: 480px){padding: .5em 5vw 0;}}
						& h4{@media screen and (max-width: 480px){padding: 0 5vw;}}
						& p{@media screen and (max-width: 480px){padding: 0 5vw;font-size: 1em;line-height: 1.5em;}}
					}
				
				}
			}
			
			
			}
	
	}
	& figure.wp-block-image.size-large img{width: 100%;height: auto;}
	&.privacy{margin:0 auto; width: 100%;
		&:before{content: none;}
		& .section{padding:200px 0;z-index: 1;position: relative;
			& p{font-size:1em; line-height: 1.5em;}
			& a{color:#fff;text-decoration: underline;}
			& ul{line-height: 1.5em;}
			& ol{line-height: 1.5em;}
		}
	}
}

.ninja-forms-form-wrap{
	
	& > div{width: 80%;margin: 0 auto;max-width: 1116px;
	@media screen and (max-width: 480px){width:100%; padding: 0 5vw; box-sizing:border-box;}
	}
	& .nf-form-layout{
		& .nf-form-content{padding:0;max-width: 1116px;}
		& .nf-before-form-content, & .nf-after-form-content{font-size: .8em;letter-spacing: 0.05em;color: gray;}
		
			
		.nf-form-content input[type=button]{white-space: nowrap;
				font-size: 14px;
				font-weight: 400;
				color: #fff;
				background: transparent;
				border: 2px solid #fff;
				outline: none;
				box-shadow: 0 0 0 0 #999;
				box-sizing: border-box;
				cursor: pointer;
				text-align: left;
				padding: 12px 15px;
				min-height: 0;
				min-width: 0;
				max-height: none;
				max-width: none;
				opacity: 1;
				text-transform: uppercase;
				text-decoration: none;
				font-family: 'Poppins',sans-serif;
				letter-spacing: .2vw;
				transition: all .2s ease-in;
				margin: 10px 0 0;
			@media screen and (max-width: 480px){display:block;margin:0 auto;}
			&:hover{background: transparent;color: #e9cf74;transition: all .2s linear;border: 2px solid #e9cf74;}
		}
		
	}
}

.nf-response-msg {
    display: block;
    width: 80%;
    margin: 10vh auto;
    text-align: center;
}



.up-sells{
	background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
	@media only screen and (max-width: 480px){padding-bottom:10vh;margin-top:40px;}
	& ul{display:flex;flex-direction: row;
		@media only screen and (max-width: 480px){flex-direction: column;}
		& li{    list-style-type: none;
			& a{text-decoration: none;
				& .product-card-images{
					& img{margin-left: 7%;
					@media only screen and (max-width: 480px){margin-left: 4%;}
					}
				}
				& h2 {
					@media only screen and (max-width: 480px){margin-left: -4%;}
					font-size: 1.25em;
					text-align: center;
					line-height: 1em;
					text-transform: uppercase;
					letter-spacing: .03em;
					display: flex;
					flex-direction: column;
					min-height: 50px;
					align-items: center;
					justify-content: center;
					color: #fff;
					text-decoration: none;
					border: none;
				}
				& .amount {
					@media only screen and (max-width: 480px){margin-left: -4%;}
					color: #e8cf74;
					display: block;
					text-align: center;
					font-size: 1em;
					letter-spacing: .05em;
				}
			}
		}
	}
	
}

#shopPage_content{
	margin: 0 auto;
    width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
	& .products{
	display: grid;
    grid-template-columns: repeat(4,1fr);
		@media screen and (max-width: 1000px){grid-template-columns: repeat(2,1fr);}
		@media screen and (min-width: 1001px) and (max-width: 1300px){grid-template-columns: repeat(3,1fr);}
    grid-column-gap: 0;
    grid-row-gap: 10px;
    margin: 0;
    padding: 0;
		@media screen and (max-width: 480px){display:flex;flex-direction: column;}
		& li{overflow: hidden;padding: 1em;
    box-sizing: border-box;
    opacity: .9;
    transition: opacity .2s linear;
			&:hover{opacity: 1;transition: opacity .2s linear;}
				& h2{    
				font-size: 1.25em;
				text-align: center;
				line-height: 1em;
				text-transform: uppercase;
				letter-spacing: .03em;
				display: flex;
				flex-direction: column;
				min-height: 90px;
				align-items: center;
				justify-content: center;
				margin-top: 10px;
					& small{text-transform: none;
						font-size: .6em;
						font-weight: 400;
						line-height: 1.3em;
						padding-top: 5px;
					@media screen and (max-width: 480px){font-size:.8em;}
					}
			}
			& a{text-decoration:none}
			& .description{ 
				& p{   font-size: .8em;
						color: #848484;
						line-height: 1.5em;
						text-align: center;
						transition: color .2s linear;
						@media screen and (max-width: 480px){font-size:1em;}
				}
			}
			& img{width: 100%;
    height: 50vh;
    -o-object-fit: cover;
    object-fit: cover;
			}
			& .price{text-align:center;
				& del{font-size:.8em;
					& span{opacity: .8;}
				}
				& ins{text-decoration: none;}
				& span{color: #e8cf74;
        display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: 1em;
    letter-spacing: .05em;}
				& .amount{
					& bdi{display:flex;flex-direction: row;}
				}
			} 
		}
		
	}
}
.woocommerce-account{
& .content-cont{width:90%;margin: 0 auto;}
	}
#customer_login {
	@media screen and (max-width: 765px){flex-direction: column;width:100%;}
    display: flex;
    flex-direction: row;
	justify-content: space-between;
    width: 80%;
    margin: 0 auto;
	& h2{ line-height: 1.5em;
	background: linear-gradient(90deg,#bf953f,#fcf6ba,#b38728,#fbf5b7,#aa771c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;}
		& input[type=text] {width: 100%;line-height: 20px;padding: 5px;box-sizing: border-box;}
		& input[type=email] {width: 100%;line-height: 20px;padding: 5px;box-sizing: border-box;}
		& input[type=password]{width: 100%;line-height: 20px;padding: 5px;box-sizing: border-box;}
		& button[type=submit]{    
			white-space: nowrap;
			font-size: 14px;
			font-weight: 400;
			color: #fff;
			border: 2px solid #fff;
			background: transparent;
			outline: none;
			box-shadow: 0 0 0 0 #999;
			box-sizing: border-box;
			cursor: pointer;
			text-align: left;
			padding: 12px 15px;
			min-height: 0;
			min-width: 0;
			max-height: none;
			max-width: none;
			opacity: 1;
			text-transform: uppercase;
			text-decoration: none;
			font-family: 'Poppins',arial;
			letter-spacing: .2vw;
			transition: all .3s ease-in;
			display: block;
			margin: 1em 0;
		}
	& .woocommerce-form-login__rememberme{
		& input{margin-right:5px;}
		& span{font-size: .8em;
			line-height: 1em;
			position: relative;
			top: -2px;}
			}
	& a{color:#eb2141;text-decoration:none;
		&:hover{color:#fff;}
	}
	
}

.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1, .woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
	border: 1px solid #fff;
    padding: 1.5em;
	width:45%;
    border-radius: 4px;
	box-sizing: border-box;
	@media screen and (max-width: 765px){
		width:100%;
		margin:0 auto 20px auto;
	}
}

.woocommerce-ResetPassword{border: 1px solid #fff;
    padding: 1.5em;border-radius: 4px;
	box-sizing: border-box;width:90%;margin:0 auto;
	& input[type=text] {width: 100%;line-height: 20px;padding: 5px;box-sizing: border-box;}
	& button[type=submit]{    
			white-space: nowrap;
			font-size: 14px;
			font-weight: 400;
			color: #fff;
			border: 2px solid #fff;
			background: transparent;
			outline: none;
			box-shadow: 0 0 0 0 #999;
			box-sizing: border-box;
			cursor: pointer;
			text-align: left;
			padding: 12px 15px;
			min-height: 0;
			min-width: 0;
			max-height: none;
			max-width: none;
			opacity: 1;
			text-transform: uppercase;
			text-decoration: none;
			font-family: 'Poppins',arial;
			letter-spacing: .2vw;
			transition: all .3s ease-in;
			display: block;
			margin: 1em 0;
		}
}

.woocommerce-MyAccount-navigation{
	width: 100%;
	& ul{    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    width: 100%;
    margin: 0 auto;
	@media screen and (max-width: 480px){flex-direction: column;    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
		}
		
		& li{
			list-style: none;
			color: #fff;
			border: 1px solid #fff;
			padding: 1.5em;
			box-sizing: border-box;
			transition: background .2s linear;
			width: -webkit-fill-available;
    		text-align: center;
			@media screen and (max-width: 480px){margin-bottom:10px;}
			&:hover{background: #fff;transition: background .2s linear;
				& a{color:#000;}
			}
			&.is-active{background: #fff;transition: background .2s linear;
				& a{color:#000;}
			}
			& a{color:#fff; text-decoration: none;}
		}
	}
}
.woocommerce-customer-details{
			& .woocommerce-columns--2{
				display: flex; flex-direction: row;justify-content: space-between;

				@media screen and (max-width: 480px){
					flex-direction: column;
					
				}
				& .woocommerce-column{border: 1px solid #000!important;width: 49%;margin-bottom: 2em;
					& h2{font-size: 1em;padding-bottom: 20px}
						@media screen and (max-width: 480px){
							width: 100%;
					
					}
				}
			}
		}
.woocommerce-MyAccount-content {
	& button[type=submit]{color:#000!important; border: 1px solid #000!important;
		&:hover{background: #000;color: #fff!important;}
	}
	width: 100%;
    margin: 100px auto;
    background: #fff;
    padding: 1em 3em 1em;
    color: #000;
    box-sizing: border-box;
	@media screen and (max-width: 480px){
		padding:1em;
		font-size: .8em;
	}
		& select{max-width: 100%;}
		& input[type=text] {width: 100%;line-height: 20px;padding: 5px;box-sizing: border-box;}
		& input[type=email] {width: 100%;line-height: 20px;padding: 5px;box-sizing: border-box;}
		& input[type=password]{width: 100%;line-height: 20px;padding: 5px;box-sizing: border-box;}
		& button[type=submit]{   
			letter-spacing: .05em;
			white-space: nowrap;
			font-size: 14px;
			font-weight: 400;
			color: #fff;
			border: 2px solid #fff;
			background: transparent;
			outline: none;
			box-shadow: 0 0 0 0 #999;
			box-sizing: border-box;
			cursor: pointer;
			text-align: left;
			padding: 12px 15px;
			min-height: 0;
			min-width: 0;
			max-height: none;
			max-width: none;
			opacity: 1;
			text-transform: uppercase;
			text-decoration: none;
			font-family: 'Poppins',arial;
			letter-spacing: .2vw;
			transition: all .3s ease-in;
			display: block;
			margin: 1em 0;
		}
	& fieldset {
    border: 1px solid #333;
    margin-top: 40px;
		& legend {padding: 0 15px;}
    }
	& .woocommerce-form-row span {font-size: .8em;}	
	& .woocommerce-MyAccount-orders{
		width: 100%;
		text-align: center;
		padding: 10px 0;
		& tr td{
			padding: 5px 0;
		}
		/* @media screen and (max-width: 765px){
			padding:0;
		} */
	}
	& table.woocommerce-table--order-details {
    width: 100%;
	background: #f2f2f287;
    padding: 2em;
    margin: 10px 0;
		& thead tr th {border-bottom: 1px solid #ccc;text-align: left;padding-bottom: 10px;}
		& td{padding: 10px 0;border-bottom: 1px solid #e2e2e2;
			& a{font-size: .8em;font-weight: normal;letter-spacing: .03em;}
			}
		& tfoot tr th {text-align: right;padding-right: 20px;}
		
}
	
	& a{color:#eb2141;text-decoration:none;
		&.edit {
			margin-bottom: 10px;
			display: block;
			font-size: .8em;
			font-weight: bold;
		}
		&:hover{color:#000;text-decoration: underline;}
		}
}

.woocommerce-Addresses{
	display: flex;
	flex-direction: row;
	@media screen and (max-width: 480px){flex-direction: column;}
	justify-content: space-between;
		& .u-column1{border: 1px solid #000!important;width:49%;margin-bottom: 2em;}
		& .u-column2{border: 1px solid #000!important;width:49%;margin-bottom: 2em;}
		
	}

.page-id-759{
	& .page {
		& section.hip {
   	
		background-attachment: scroll;
	}
	}
	& #nf-form-1-cont{ 
		& .nf-form-layout {
		width: 100%;
		margin-top: 80px;
		}
	}
	& .nf-before-form-content{margin-bottom: 20px;}
	& .content-cont p{
		margin: 0;
		&.top_words{margin-top: 15px;font-weight: bold;font-size: 1.3em;margin-bottom: 5px;}
	}
}
