.people {
    display: flex;
    padding: 3em;
    margin-top: 20vh;
    &  img {
        width: 100%;
    }
    & .wp-block-column {
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
    }

    & .wp-block-group.people-info {
        position: absolute;
        
    }

     & .wp-block-group.people-info-left {
        top: 20%;
        left: 10%;
        
    }
    & .wp-block-group.people-info.people-info-right {
        top: 20%;
        right: 10%;
    }
    & .wp-block-group.people-info.people-info-center {
        bottom: 20%;
        left: 0;
        right: 0;
        margin: auto;
        width: 165px;
    }
    & p.people-name, p.people-title {
        margin-bottom: 0;
        margin-top: 0;
        text-transform: uppercase;
    }

    & p.people-title {
        font-size:.7em;
        color:#e1b77b
    }
    & .people-title , .people-subtitle , .people-text{
        text-align: center;
       
    }
    & .people-title , .people-subtitle{
       
        text-transform: uppercase;
    }
}
.first-section {
    background: #000;position:relative;display:flex;align-content: center;justify-content: center;padding-top:5vh;box-sizing: border-box;
	&:before{    content: "";
    display: block;
    position: absolute;
    width: 100%;
    top: -28vh;
    height: 30vh;
    background: linear-gradient(180deg,transparent 25%,rgba(0,0,0,.9) 75%,#000);
    z-index: 9;
    transform: translate(180deg);
    filter: blur(2px);
	}
	
}
.home section {
    min-height: 100vh;
}

.product_home_section{background:#000;}
.section-coffee{    
	background: #000;
	& .wp-block-group__inner-container{
		/*height: 112vh;*/
		width:100%!important;
    	position: relative;
		& h2{width:80%;margin:0 auto;}
		& figure{margin:0; padding:0;
			& img{max-width:100%;width:100%;margin: 50px 0;
			
			@media screen and (max-width: 480px){ object-fit: cover;height: 100%;width: initial; }
			}	
		}
		& p{ width: 80%; margin:0 auto;
			& span{    background: #000;}
			
		}
		& a.wp-block-button__link{
				display: inline-block;		
				margin: 3vh auto 0;
			}
	}
}

.wp-block-group.centered-container.centered-text.mt-50 p {
    max-width: 70%;
    position: relative;
    margin: auto;
    padding: 1em;
}

.courses {
	width: 100%;
	background: #000;
    margin: 0;
    min-height: 90vh;
    display: flex;
    align-items: center;
	& .wp-block-group__inner-container{width:90%;margin: 0 auto;}
    & .wp-block-columns{
        display: flex;
		@media screen and (max-width: 480px){ flex-direction: column; }
    }
    
    & .wp-block-column {
		&:nth-child(1){padding:0 0 0 0; position:relative;margin: 0;filter: brightness(1.5);
			& figure{
				position:relative;    margin: 0;
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: url(//www.caffeideal.com/wp-content/themes/ideal2020/images/map-gradient-min.png;);
					z-index: 3;
					display: block;
					background-size: 100% 100%;
					}
			}
		
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			bottom: 0;
			height: 20vh;
			background: linear-gradient(180deg,transparent 25%,rgba(0,0,0,.9) 75%,#000);
			z-index: 9;
			filter: blur(2px);
			}
		}
		&:nth-child(2){padding:0 5vw 0 0;
			@media screen and (max-width: 480px){padding:0; text-align:center; }
				}
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
		
         &  img {
            width: 100%;
        }
		@media screen and (max-width: 480px){width:100%;}
    }
	& a.wp-block-button__link{display:inline-block;}
} 
.openings{
    & .wp-block-columns{
        display: flex;
    }
    
    & .wp-block-column {
        position: relative;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
         &  img {
            width: 100%;
        }
    }
    & .wp-block-column.days {
        width: 25%;
        padding-top: 2em;
        & p{
            margin: 0 1em;
        }
    }
    & .wp-block-column.hours {
        width: 75%;
        &  figure.wp-block-table {
            margin: 0 1em;
        }

        &  tr {font-weight: 400;text-shadow: none;font-size: 15px;line-height: 22px;width: 100%;}
        &  tr td{
            width:50%
        }


        &  figure.wp-block-table {
            margin: 0 1em;
        }

        &  table {
            width: 100%;
        }


    }
    &  > .wp-block-group__inner-container {
        width: 100%;
        height: 100vh;
        position: relative;
    }

    & #home-map {
        height: 100%;
		height: calc(100% + 4px);
        width: 100%;
		overflow: visible;
		&:before{
            background:url(//www.caffeideal.com/wp-content/themes/ideal2020/images/map-gradient-min.png;);
            content: "";
            height: 100%;
            width: 100vw;
            display: block;
            position: absolute;
            top: 0;
            background-size: cover;
            left: 0;
            z-index: 2;
            overflow: visible!important;
		}
		&:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            bottom: -10px;
            height: 30vh;
            background: linear-gradient(180deg,transparent 25%,rgba(0,0,0,.9) 75%,#000);
            z-index: 9;
            filter: blur(2px);
                    
        }
		
        & ~ .wp-block-group{
            z-index: 9;
            position: absolute;
            top: 30%;
            left: 10%;
			background: rgba(0, 0, 0, 0.75);
            padding: 2em;      
            width: 30%;
			@media only screen and (max-width: 480px){width: 90%;box-sizing: border-box;text-align: center;border-radius: 5px;left: 5%;background:none;}
			
			& p{padding: 0;margin: 0;
				&.top_words{text-transform: uppercase;
						color: #e9cf74;font-weight: 700;line-height: 1.2em;margin-bottom: 15px;
				}
			}
        }
    }
    & #home-map.fallback-mobile-bg{
        background-position: top;
        background-size: cover;
    }
} 

.services{
    & .wp-block-columns{
        display: flex;
    }
    
    & .wp-block-column {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 50%;
        /* align-items: center; */
        justify-content: center;
         &  img {
            width: 100%;
        }
    }
}



.service_list{
	min-height: 100vh;
	padding-top: 10vh;
	background: #000;
    align-items: center;
    justify-content: center;
    display: flex;
	& .wp-block-group__inner-container{
		width:80%;margin:0 auto;
		@media only screen and (max-width: 600px) {width:100%;margin:0 15px;}
	& .wp-block-columns{
		display:flex;flex-direction: row;
		@media screen and (max-width: 480px){ flex-direction: column; }
		
		& .wp-block-column{ display: flex; flex-direction: column; float: left;width:33%; padding:1em; box-sizing: border-box;
			@media screen and (max-width: 480px){ width:100%; }
			& p.top_words {
							text-transform: uppercase;
							color: #e9cf74;
							font-weight: bold;
							@media screen and (max-width: 480px){ text-align:center; }
						}
			& .wp-block-image{margin:0 0 20px 0;padding:0;
				& img{width:100%;height: auto;}
				
			}
			& h2{   font-size: 1.25em;
					text-transform: uppercase;
					line-height: 1.2em;
				@media screen and (max-width: 480px){ text-align:center; }
			}
			& p{    line-height: 1.5em;
    font-size: .9em;
			@media screen and (max-width: 480px){ text-align:center; }
			}
		}
		}
	
	}
	& .wp-block-button{@media screen and (max-width: 480px){ text-align:center; }}
	& a.wp-block-button__link{display:inline-block; margin-top:15px;}
}

.product_home_section{
	& .wp-block-group__inner-container{width: 100%;
    margin: 0 auto;
    }
	& .VueCarousel{width:100%;padding: 0 10% 10vh; box-sizing: border-box;}
	& .slider-container{
        width: 100%;    
        margin: auto;
        overflow: hidden;
        position: relative;
        z-index: 0;

        & h2{
            font-size: 1.5em;
        }

        & .slider-content{
            z-index: 1;
            display: flex;
            flex-wrap: nowrap;
            transition:all .3s ease-in-out;
            & .slider-slide{
                margin: 0;
                padding: 1em;
                box-sizing: border-box;
				opacity: .9;
				transition: opacity .2s linear;
					&:hover{opacity: 1;transition: opacity .2s linear;}
            }
        }
   

        & .VueCarousel{
            & .VueCarousel-pagination {
                order: 0;
            }
            & .VueCarousel-wrapper {
                order: 1;
            }
            & .VueCarousel-slide{
				&:hover{
					& p{color: #fff;transition: color .2s linear;}
				}
                & img {
                    width: 100%;
                    height: 50vh;
                    object-fit: cover;
                }  
				& h2{   font-size: 1.25em;
						text-align: center;
						line-height: 1em;
						text-transform: uppercase;
						letter-spacing: .03em;
						display: flex;
						flex-direction: column;
						min-height: 90px;
						align-items: center;
						justify-content: center;
						margin-top: 10px;
					
					& small{
							text-transform: none;
							font-size: .6em;
							font-weight: normal;
							line-height: 1.3em;
							padding-top:5px;
							@media screen and (max-width: 480px){     font-size: .8em;margin-bottom: 1em; }
						   }
				}

				& p{font-size:.8em;color: #848484;line-height: 1.5em;text-align: center;transition: color .2s linear;
					@media screen and (max-width: 480px){     font-size: 1em; }
				
				}
				& .woocommerce-Price-amount.amount {
					color: #e8cf74;
					display: block;
					text-align: center;
					font-size: 1.0em;
					letter-spacing: .05em;
					}
								
            }   
            & .VueCarousel-navigation-prev{
                transform: translateY(-50%);
            }
            & .VueCarousel-navigation-next{
                transform: translateY(-50%);
            }
            & .VueCarousel-navigation-button{
				
				&:focus{outline:none!important}
                color: #e3cb71;
                background: transparent;
                font-size: 32px;
    			margin: 0 10px;
				
				@media screen and (max-width: 480px){ margin:0; border-radius:0;font-family:system-ui; }
            }
        }
        
    }
	
}




