#map {
    top:0; 
    height: 100vh;
    width:100vw;
    position: fixed;
    z-index: -5;
}
#features {
       padding-top: 40vh;
    padding-bottom: 10vh;
    z-index: 100;
    margin-top: -100vh;
}

 
.centered {
    width: 50vw;
    margin: 0 auto;
}

#story{
&::before {
    content: "";
	background:url(//www.caffeideal.com/wp-content/themes/ideal2020/images/overMask-min-min.png) left top no-repeat;
	background-size: cover;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
	@media screen and (max-width: 480px){height:auto;}
    z-index: 0;
    display: block;
	transform: translate3d(0);
	}	

& .lefty {
    width: calc(46% - 5vw);
    margin-left: 5vw;
	@media screen and (max-width: 480px){width:70vw;}
	& .light { color: whitesmoke;
		& h2{
background: -webkit-linear-gradient(left, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
background: -o-linear-gradient(left, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
  	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    font-size: 2em;
    line-height: 1.5em;
    padding-top: 0;
    margin-top: 0;
    font-weight: bold;
			
		}
}
	& #record-0:before{content: "La sintesi perfetta di oltre 70 anni di esperienza";
    font-size: 2.3em;
    margin: 0 15px 10vh;
    display: block;
    text-transform: none;
    font-weight: bold;
	@media screen and (max-width: 480px){font-size: 2em;}
	}	
	}
	& .step {
    margin-bottom: 50vh;
    opacity: 0.25;
		&.active {opacity: 0.9;}
		& > div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 15px;
				line-height: 25px;
				font-size: 13px;
				flex-direction: column;
				}
		& p{
			& p{margin: 0;
    font-size: 1.1em;
    line-height: 1.5em;}
		}
		& .img_cont{
			    width: 100%;
				position: relative;
				margin-left: -5vw;
				@media screen and (max-width: 480px){display:none;}
			& img{width:100%;}
			&:before{
				content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(//www.caffeideal.com/wp-content/themes/ideal2020/images/map-gradient_ideal.png);
    z-index: 3;
    display: block;
    background-size: cover;
    filter: contrast(2) grayscale(1);
				}
			&:after{content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 100%;
    background: url(//www.caffeideal.com/wp-content/themes/ideal2020/images/map-gradient_ideal.png);
    z-index: 3;
    display: block;
    background-size: cover;
    filter: contrast(2) grayscale(1);
    transform: rotate(180deg);}
		}
	}
		}
		

.righty {
    width: 33vw;
    margin-left: 62vw;
}

.dark {
    color: #fafafa;
    background-color: #444;
}




#chapter-INIZIO img {
    width: 12em;
}
.marker.mapboxgl-marker {
    background-size: contain;
}
.mapboxgl-marker svg {
        display: none; 
}


@media (max-width: 750px) {
    #features {
        width: 90vw;
        margin: 0 auto;
    }
}