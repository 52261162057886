button#place_order {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    background: #f2f2f2;
    border: 1px solid #000;
    outline: none;
    box-shadow: 0 0 0 0 #999;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;
    padding: 12px 15px;
    min-height: 0;
    min-width: 0;
    max-height: none;
    max-width: none;
    opacity: 1;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Poppins',sans-serif;
    letter-spacing: .2vw;
    transition: all .2s ease-in;
    margin: 10px 0 0;
	
	&:hover{background: #000;
    color: #fff;
    transition: all .2s linear;
    border: 1px solid #000;}
}

.shipping-calculator-button{width: 81%;
    text-align: left;
    text-decoration: underline;
    display: block;
    color: #f44336;
    line-height: 3em;
    font-weight: 400;
    font-size: .8em;
    margin: 1em 0 0;
    cursor: pointer;
    letter-spacing: .05em;
	&:hover{text-decoration: none;}
	}



@media(min-width: 1100px) {}
	.woocommerce-checkout{
	& .woocommerce{flex-direction: column;    
		display: flex;
		margin: 0 auto;
		background: #fff;
		color: #000;
		padding: 1em;
		width: 85%;
		

		& .woocommerce-form-coupon-toggle{background:#f2f2f2;padding:1em;
			& a.showcoupon{font-weight: bold;color:#eb2141;display: block;margin-top: 5px;
				&:hover{text-decoration:none;}
			}
		}
			& #customer_details {
				width: 100%;
				display: flex;
				flex-direction: row;
				@media only screen and (max-width: 480px){flex-direction:column;}
				& .woocommerce-billing-fields__field-wrapper{
					& p{@media only screen and (max-width: 480px){margin:0 0 10px;}}
					& select{@media only screen and (max-width: 480px){width: 100%;line-height: 1.5em; padding: .4em 0; }}
				}
				& .col-1{width:50%; @media only screen and (max-width: 480px){padding:0; width: 100%;}}
				& .col-2{width: 50%; @media only screen and (max-width: 480px){padding:0;width: 100%;}
					& textarea#order_comments {
						margin: 5px 0;
						width: 100%;
						height: 125px;
						padding: .5em;
						box-sizing: border-box;
						@media only screen and (max-width: 480px){width: 100%;margin: 10px 0;height: 6em;line-height: 1.5em;box-sizing:border-box;}}
					
					& h3#ship-to-different-address{background: #f2f2f2;}
					& .shipping_address{display:none;}
				}
				& label{display:block;
					&.checkbox{display:flex;flex-direction: row;
						& input{width: 30px;}
						& textarea{width: 90%; @media only screen and (max-width: 480px){width:100%;}
								height: 120px;
								padding: .5em;
								box-sizing: border-box;}
						& span{font-size: .7em;line-height: 2.8em;}
					}
				}
				& input {
					width: 90%;  @media only screen and (max-width: 480px){width:100%;}
					margin: .5em 0;
					line-height: 2em;
					padding: 0 0.5em;
    				box-sizing: border-box;
				}
			}
		}
		#order_review_heading{margin-top:2em}
		#order_review{
					    padding: 1em;
						box-sizing: border-box;
						width: 100%;
						border: 1px solid #000;
			& .woocommerce-checkout-review-order-table{
					width: 100%;
					background: #fff;
					border: none;
					border-collapse: collapse;
					& thead{background: linear-gradient(90deg,#bf953f,#fcf6ba,#b38728,#fbf5b7,#aa771c);line-height: 3em;font-size: .8em;}
					& tbody{line-height: 3em;text-align: center;    }
					& tfoot {
						background: #f2f2f2;
						border: none;
						border-collapse: collapse;
						padding: 1em;
						display: table-footer-group;
						font-size: .8em;
						letter-spacing: .02em;
						text-align: center;
						& .cart-subtotal{line-height:3em;}
						& .order-total{line-height:3em;}
						& ul#shipping_method {padding: 0;margin: 0;
							& li{list-style-type: none;margin: 0;padding: 0;}
						}
						& span.woocommerce-Price-amount {font-weight: bold;font-size: 1.1em;}
						& .product-name{@media only screen and (max-width: 480px){line-height: 1.5em;padding: 1em 0!important;}}
					}
				}
			}
	}

.cart-collaterals{
	overflow: hidden;
	& .cross-sells{
		& h2{    font-size: 1.2em;
    margin: 1em 0;
    text-transform: uppercase;}
		& .products{padding:0;margin:0;display: flex;justify-content: space-between;flex-wrap: wrap;
			& li{width: 49%;
				display: block;
				border: 1px solid #333;
				box-sizing: border-box;
				overflow: hidden;
				margin: 0;
				& a{text-decoration: none;}
			
			& .product-card-images{
				width: 100%;
  				box-sizing: border-box;
				& img{
					width: 120%;
					height: auto;
					margin-left: -4%;
					display: block;
					margin-top: 10%;
					}
				}
				& h2.woocommerce-loop-product__title {
					font-size: 1.25em;
					text-align: center;
					line-height: 1em;
					text-transform: uppercase;
					letter-spacing: .03em;
					display: flex;
					flex-direction: column;
					min-height: 50px;
					align-items: center;
					justify-content: center;
					color: #fff;
					text-decoration: none;
					border: none;
					margin: 0 1em;
				}
				& .price{display:flex; flex-direction: row;justify-content: center;margin-bottom: 1em;
					& .amount {
						color: #e8cf74;
						display: block;
						text-align: center;
						font-size: 1em;
						letter-spacing: .05em;
					}
				}
				
			}
		}
	}
  }



#payment{
	& .wc_payment_methods{
		padding: 0;
  	    margin: 30px 10px;
		& .wc_payment_method{
			list-style: none;
			&.payment_method_paypal{
				& img{position: relative;top: 7px;margin-left: 10px;width: auto;height: 25px;}
			}
			& a.about_paypal{
    font-size: .8em;
    color: #000;
    font-weight: bold;
    text-decoration: none;
    padding-left: 10px;
				&:hover{color:#eb2141;}
	}
			& .payment_box{
				& p{margin: 0;font-size: .8em;margin-top: 10px;}
			}
		}
	}
	& .woocommerce-terms-and-conditions-wrapper{border-top: 1px solid #000;border-bottom: 1px solid #000;margin-bottom: 20px;
		& .woocommerce-privacy-policy-text{
			& p{font-size: .8em;line-height: 1.5em;margin: 20px 0;}
			& a.woocommerce-privacy-policy-link{color:#eb2141;text-decoration:none;font-weight: bold;
				&:hover{text-decoration:underline;}
				}
			}
	}
	
}


.woocommerce-cart {

& .woocommerce {
    display: flex;
	width:80%;
	margin:0 auto;
	position: relative;
	& .woocommerce-notices-wrapper {
		position: absolute;
		top: -30px;
		left: 0;
		@media screen and (max-width: 480px){position: initial;}
	}
	& .wc-backward {
		color: #f44336;
		text-decoration: none;
		font-weight: bold;
		&:hover{text-decoration: underline;}
	}
	@media screen and (max-width: 480px){ width:90%;flex-direction: column;}
 	}
	& .wp-block-group__inner-container{@media screen and (max-width: 480px){ max-width: 100%;}}
	& .woocommerce-notices-wrapper{
		& a{color:#f44336; text-decoration: none;font-weight: bold;}
		@media screen and (max-width: 480px){padding: 1em;font-size: .9em;text-align: center;letter-spacing: .05em;}
	}
}


.woocommerce-cart .woocommerce-cart-form {
    flex: 1 0 70%;
    margin-right: 0px;
	@media screen and (max-width: 480px){ max-width: 100%;order: 2;}
	
    & .shop_table{background: #fff;box-sizing: border-box;color:#000;width:100%;
	& thead{ 
			background: #f2f2f2;@media screen and (max-width: 480px){display:none;}
			& th {
				text-align: left;
				border-top: 1px solid #ccc;
				margin-bottom: 10px;
				padding: .75em 1em;
				font-size: .8em;
				line-height: 2.5em;
				&.product-price{padding:0; text-align:left;}
				&.product-subtotal{text-align:right;}
			}
		}
	}
	& .cart_item{
		
		&:nth-of-type(odd){background:#fff;}
		&:nth-of-type(even){background:#f2f2f2;}
		& .product-remove{text-align: center;
			& a{text-decoration: none;
				background: #f44336;
				color: #fff;
				border-radius: 50%;
				width: 20px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 15px;
				@media screen and (max-width: 480px){margin: 20px auto;height: 32px;width: 32px;line-height: 29px;font-size: 25px;display: block;}
			}
		}
		& .product-thumbnail{
			@media screen and (max-width: 480px){text-align: center;}
			& img{width: auto;height: 100px;padding:1em;
				@media screen and (max-width: 480px){width: 90%;padding: 0 5%;height: auto;}
			}
		}
		& .product-name{
			padding: 1em;
			@media screen and (max-width: 480px){text-align: center;}
			& a{text-decoration: none;font-size: 1em;color: #000;font-weight: bold;
				&:hover{text-decoration: underline;}
			}
		}
		& .product-price{
			@media screen and (max-width: 480px){text-align: center;padding:10px 0; display:block;}
			& .amount{font-size: .8em;padding:1em 2em 1em 0;
			@media screen and (max-width: 480px){ padding:0;font-size: 1em;}
			}
		}
		& .product-quantity{
			text-align: center;
			& label{font-size: .8em;display: none;}
			& input.qty {width: 35px;font-size: .8em;padding: .5em;}
		}
		& .product-subtotal{text-align: right;padding: 0 1em 0 0;border: 1px solid #eee;
		
			@media screen and (max-width: 480px){   text-align: center;
    padding: 10px 0;
    font-weight: bold;
    border-top: 1px solid #f2f2f2;
    margin-top: 10px;}
		}
		
		@media screen and (max-width: 480px){ display: flex;flex-direction: column;width: 100%;flex: 1;}
	}
	
	.coupon{padding: 1em;width: 100%;float: left;box-sizing: border-box;
		& label{font-size: .8em;}
		& .input-text{
			font-size: .8em;
			min-width: 165px;
			margin-right: 10px;
			line-height: 2em;
			padding: 0.5em 1em;
			margin-left: 10px;
			@media screen and (max-width: 480px){margin: 5px 0 0;width: 100%;box-sizing: border-box;}

		}
		.button[name=apply_coupon]{line-height: 2em;
			font-size: .8em;
			border: none;
			background: #607D8B;
			color: #fff;
			padding: .5em 1em;
			text-decoration: none;transition: background .2s linear;
			@media screen and (max-width: 480px){display: block;width: 100%;margin-top: 20px;padding: 1em 0;}
			&:hover{background:#466473;transition: background .2s linear;}
		}
	}
	
	.button[name=update_cart] {
		    background-color: #e2e2e2;
			line-height: 2em;
			font-size: .8em;
			border: none;
			background: #3b515c;
			color: #fff;
			padding: 0.5em 1em;
			text-decoration: none;cursor: pointer;		
			@media screen and (max-width: 480px){display: block;width: 100%;margin-top: 20px;padding: 1em 0;}	
			&:hover{background:#1a262b;color: #fff;transition: background .2s linear;}
		}
  }    
.woocommerce-cart .cart-collaterals {
	flex: 1 0 30%;
    flex: 1 0 calc(30% - 20px);
    margin-left: 20px;
    flex-direction: column;
    width: 100%;
    display: flex;
	@media screen and (max-width: 480px){margin-left:0;margin-bottom:20px;flex: 1;width: 100%;order: 1;}
    
	& .cross-sells{order: 2;}
	& .cart_totals {background: #fff;color:#000;box-sizing: border-box;order: 1;
		& h2{    padding: .75em 1em;
    font-size: .8em;
    line-height: 2.5em;
			text-align: center;
			background: #f2f2f2;		
			font-weight: bold;
			}
		& .shop_table{width:100%;margin:0;padding:0;}
		& .cart-subtotal{
			& th{border-bottom: 1px solid #f2f2f2;
					border-right: 1px solid #f2f2f2;
					text-align: right;
					padding: 1em 1em;font-size: .9em;
					font-weight: normal;
					width: 40%;
					box-sizing: border-box;}
			& td{border-bottom: 1px solid #f2f2f2;text-align: left;padding: 0 1em;}
		}
		& .woocommerce-shipping-totals {
			& th{width: 40%;border-right: 1px solid #f2f2f2;text-align: right;font-size: .9em;
					padding: .5em 1em;border-bottom: 1px solid #f2f2f2;font-weight: normal}
			& td{border-bottom: 1px solid #f2f2f2;font-size: .9em;
				 text-align: left;
				 padding: 1.5em 1em;
				 box-sizing: border-box;
				 font-size: 0.8em;
				& span.woocommerce-Price-amount.amount{font-weight: bold;}
				& p{margin: .4em 0;font-size: 1em;line-height: 1.3em;}
				& ul#shipping_method{margin:0; padding:0;
					& li{list-style-type: none;letter-spacing: .03em;}
				}
			}
		}
		& .order-total{
			& th{
					border-right: 1px solid #f2f2f2;
					text-align: right;
					padding: 1em 1em;font-size: .9em;
					font-weight: normal;
					width: 40%;
					box-sizing: border-box;}
			& td{text-align: left;padding: 2em 1em;}
						
		}
		& .wc-proceed-to-checkout{
			& .checkout-button{
					cursor: pointer;
				    text-decoration: none;
					color: #fff;
					background: #f44334;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					line-height: 6em;transition: background .2s linear;
				&:hover{background: #3b515c;transition: background .2s linear;}
				
			}
			& .woo_pp_cart_buttons_div {display: none;}
		}
	}
  }


/*
.button[name="apply_coupon"] {
  background-color: transparent;
  color: #13aff0;cursor: pointer;
}


.button[name="apply_coupon"]:hover {
  text-decoration: underline;
  background-color: transparent;
  color: #13aff0;cursor: pointer;
}


.button[name="update_cart"] {
  background-color: #e2e2e2;
  color: #13aff0;cursor: pointer;
}


.button[name="update_cart"]:hover {
  background-color: #e2e2e2;
  color: #13aff0;
  filter: brightness(115%);
}
*/

  

/* FAQ ACCORDION */

.faq_section{
	margin-top: 2vh;
    background: #fff;
    padding: 0;
	color:#000;
	& h2{padding: .75em 1.25em;
    font-size: .8em;
    line-height: 2.5em;
    text-align: left;
    background: #f2f2f2;
    font-weight: 700;}
	& h3{color:#000;font-size: 1em;}
	
	& .transition, & p, & ul li i:before, ul li i:after {transition: all 0.2s linear;}
	& p {
  color: #000;
  font-size: .9em;
  line-height: 1.5em;
  letter-spacing: 0.03em;
  position: relative;
  overflow: hidden;
  max-height: 800px;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 14px;
  z-index: 2;
}

& ul {
  list-style: none;
  perspective: 900;
  padding: 1em;
  margin: 0;
	& li {
	  position: relative;
	  padding: 0;
	  margin: 0;
	  padding-bottom: 4px;
	  padding-top: 18px;
	  border-top: 1px dotted #dce7eb;
	  @media screen and (max-width: 480px){ padding: 18px 25px 4px 0;}	
		&:nth-of-type(1) {animation-delay: 0.5s; border-top:none;}
		&:nth-of-type(2) {animation-delay: 0.75s;}
		&:nth-of-type(3) {animation-delay: 1s;}
		&:last-of-type {padding-bottom: 0;}
		& i {
		  position: absolute;
		  transform: translate(-6px, 0);
		  margin-top: 16px;
		  right: 0;
			&:before,  &:after {
			  content: "";
			  position: absolute;
			  background-color: #009688;
			  width: 3px;
			  height: 9px;
			}
			&:before {transform: translate(-2px, 0) rotate(45deg);}
			&:after {transform: translate(2px, 0) rotate(-45deg);}	
		}
		& input[type=checkbox] {
			  position: absolute;
			  cursor: pointer;
			  width: 100%;
			  height: 100%;
			  z-index: 1;
			  opacity: 0;
			
				&:checked ~ p {
				  margin-top: 0;
				  max-height: 0;
				  opacity: 0;
				  transform: translate(0, 50%);
				}
				&:checked ~ i:before {transform: translate(2px, 0) rotate(45deg);}
				&:checked ~ i:after {transform: translate(-2px, 0) rotate(-45deg);}
			}
	}
}

	
}
	


.flipIn, h1, ul li {
  animation: flipdown 0.2s linear both;
}

.no-select, h2 {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}

form.checkout_coupon {
    background: #eb2240;
    padding: .5em 2em;
    color: #fff;
			& input#coupon_code{width: 100%;padding: 1em;box-sizing: border-box;line-height: 1em;color: #000;}
			& button{
				@media screen and (max-width: 480px){text-align:center;white-space: normal;}
				max-width: 100%;
				box-sizing: border-box;
					white-space: nowrap;
				
					font-size: 14px;
					font-weight: 400;
					color: #000;
					background: #fff;
					border: 1px solid #bd1d34;
					outline: none;
					box-shadow: 0 0 0 0 #999;
					box-sizing: border-box;
					cursor: pointer;
					text-align: left;
					padding: 12px 15px;
					min-height: 0;
					min-width: 0;
					max-height: none;
					max-width: none;
					opacity: 1;
					text-transform: uppercase;
					text-decoration: none;
					font-family: 'Poppins',sans-serif;
					letter-spacing: .05vw;
					transition: all .2s ease-in;
					margin: 10px 0 0;
				
							&:hover{background: #bd1d34;color: #fff;border: 1px solid #e64056;transition: all .2s linear;}
		}
	}
		
/* p.return-to-shop {
    padding-top: 6em;
} */