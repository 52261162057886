h2{font-size: 2.5em;padding: 0;margin: 0;
	&.has-text-align-center{text-align:center;}
	&.gold{background: linear-gradient(90deg,#bf953f,#fcf6ba,#b38728,#fbf5b7,#aa771c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;}
}
h3{
	&.has-text-align-center{text-align:center;}
}
p:empty{display:none;}
a.wp-block-button__link {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    outline: none;
    box-shadow: rgb(153, 153, 153) 0px 0px 0px 0px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;
    margin: 0px;
    padding: 12px 15px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Poppins', arial;
    letter-spacing: .2vw;
    transition: all .3s ease-in;
    margin-top: 10px;
    display: none;
	
	&:hover{}
}

.centered-container{
	position: relative;
	& .wp-block-group__inner-container{width:80%; margin:0 auto;}
}
.centered-text{
    text-align: center;   
}


.logo-small{
    width: 12em;
    position: relative;
    margin: auto;
    margin-bottom: 10vh;
    & img{
        width: 100%;
        height: 100%;
    }
}

.mt-50{
    margin-top:50vh;
}
.mt-20{
    margin-top:20vh;
}

.main:before {
    content: "";
    width: 100%;
    height: 100%;
    background: url(//www.caffeideal.com/wp-content/themes/ideal2020/images/map-gradient-min.png;);
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background-size: cover;
}


.add-remove-container{
	text-align: center;
	margin-top:-25px;
	& button{
		width: 50px;
		height: 50px;
		border: 1px solid transparent;
		background: #e3cb71;
		overflow: hidden;
		position: relative;
		transition: width .2s ease-in-out;
		
		&:hover {
			width: 120px;
			}
		&:before, &:after{transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
					  content: "";
					  position: absolute;
					  height: 4px;
					  width: 10px;
					  top: calc(50% - 2px);
					  background: #28211f;
		}
		&::after{right: 14px;
			  overflow: hidden;
			  border-top-right-radius: 2px;
			  border-bottom-right-radius: 2px;
			}
		&::before {
			  left: 14px;
			  border-top-left-radius: 2px;
			  border-bottom-left-radius: 2px;
				}
		&:focus {
			  outline: none;
			}
		& .btn-txt {
			opacity: 0;
			transition: opacity .2s;
			font-size: .8em;
			color: #28211f;
			letter-spacing: .03em;
			cursor:pointer;
				}
	}
}



.add-btn:hover::before,
.add-btn:hover::after {
  width: 4px;
  border-radius: 2px;
}
.add-btn:hover .btn-txt {
  opacity: 1;
}
.add-icon::after,
.add-icon::before {
  transition: all 0.2s ease-in-out;
  content: "";
  position: absolute;
  height: 20px;
  width: 2px;
  top: calc(50% - 10px);
  background: #28211f;
  overflow: hidden;
}
.add-icon::before {
  left: 22px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.add-icon::after {
  right: 22px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.add-btn:hover .add-icon::before {
  left: 15px;
  height: 4px;
  top: calc(50% - 2px);
}
.add-btn:hover .add-icon::after {
  right: 15px;
  height: 4px;
  top: calc(50% - 2px);
}

.slide-button-disabled{
    display: none;
}