.page.privacy .content-cont {
    width: 55%;
    @media screen and (max-width: 768px){
        width: 100%;
    }
}

table.woocommerce-table.woocommerce-table--order-details.shop_table.order_details {
    width: 100%;
}

td.woocommerce-table__product-name.product-name {
    text-align: center;
}

table.woocommerce-table.woocommerce-table--order-details.shop_table.order_details .woocommerce-table__product-name product-name a {
    color: black;
}

.page.privacy .section td.woocommerce-table__product-name.product-name a {
    color: black;
}

section.woocommerce-order-details {
    padding-bottom: 2em;
}

p.woocommerce-notice.woocommerce-notice--success.woocommerce-thankyou-order-received {
    padding-left: 40px;
    @media screen and (max-width: 480px){
        padding-left: 0;
    }

} 
.page.privacy table__product-total.product-total {
    text-align: center;
}

.page.privacy tfoot tr td {
    text-align: center;
}
.page.privacy  td.woocommerce-table__product-total.product-total {
    text-align: center;
}

h2.woocommerce-order-details__title {
    padding-left: 40px;
    padding-bottom: 1em;
}
ul.woocommerce-order-overview.woocommerce-thankyou-order-details.order_details {
    list-style: none;
    @media screen and (max-width: 480px){
        padding-left: 20px;
    }
}

.woocommerce-order > p {
    padding-left: 40px;
    @media screen and (max-width: 480px){
        padding-left: 0;
    }
}

section.woocommerce-bacs-bank-details {
    padding-left: 40px;
    @media screen and (max-width: 480px){
        padding-left: 0;
    }
}
ul.wc-bacs-bank-details.order_details.bacs_details {
    
    @media screen and (max-width: 480px){
       padding-left: 20px;
    }
}







