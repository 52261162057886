footer#nextPage_anim_cont {
    min-height: 100vh;
	background: #000;
    padding:0;
	position: relative;
		& .social-list_infooter{
			    position: absolute;
				z-index: 999;
				bottom: 40px;
				display: flex;
				flex-direction: row;
				justify-content: center;
				width: 100vw;
				padding: 0;
				margin: 0;
				left: 0;
			    @media screen and (max-width: 480px){bottom: 70px;font-size: 1.5em;}
			
			& li{list-style-type: none;margin:0 7px 0 0;
				@media screen and (max-width: 480px){margin: 0 1em 0 0;}
				& a{color: #fff;}
			}
		}
		& .bg_nextPage {
			position: absolute;
			z-index: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 60vh;
			background-color: #000;
			background-attachment: fixed;
			background-size: contain;	
			
			@media screen and (max-width: 480px){background-size: cover;background-repeat: no-repeat;background-position: bottom;background-attachment: initial;}
			
			
			&:after {
			content: "";
			height: 100%;
			width: 100vw;
			background: url(//www.caffeideal.com/wp-content/themes/ideal2020/images/footer-gradient.png);
			filter: contrast(2);
			display: block;
			position: absolute;
			top: 0;
			background-size: cover;
			left: 0;
			z-index: 10;
			}	
			@media screen and (max-width: 480px){&:after{background:none;display: none;}   }
		}
		& .second_copy {
			font-family: 'Poppins',sans-serif;
			position: absolute;
			bottom: 1em;
			font-size: .8em;
			text-align: center;
			width: 100%;
			line-height: 1em;
			letter-spacing: .05em;
			font-weight: normal;
			z-index: 9999999;
			& a{color:#fff;text-decoration: none;
				&:hover{text-decoration:underline;}
			}
			@media screen and (max-width: 480px){    line-height: 1.5em;   }
			}
	
	&  .contacts-baloon {
    background-color: #e8cf74;
    position: relative;
    margin: auto;
	font-size:14px;
    top: -25vh;
    border-radius: 50%;
    width: 50vh;
    height: 50vh;
    min-width: 280px;
    min-height: 280px;
    transform: scale(.001);
    transform-origin: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 20px 35px rgba(11, 10, 7, 0.77);
		& a{
			color:#fff;
			text-decoration: none;
		}
		@media screen and (max-width: 480px){
			top: -5vh;
			width: 30vh;
    		height: 30vh;
		}
	}


		
}

			
			
          .contacts-vertical-line {
            position: relative;
            width: 10px;
            margin: auto;
            height: 0;
            background-color: #e8cf74;
          }
			
        
			
			h1#baloon-title {
				position: relative;
				font-size: 2em;
				text-transform: uppercase;
				font-family: 'Poppins',sans-serif;
				color: #28211f;
				line-height: 1em;
				width: 80%;
				margin: 0 auto .5em auto;
				text-align: center;
			
				
					}
          .baloon-txt p {
         	max-width: 90%;
			position: relative;
			margin: auto;
			font-family: 'Poppins',sans-serif;
			color: rgba(40, 33, 31, 0.8);
			font-weight: 400;
			width: 40vw;
			box-sizing: border-box;
			display: block;
			font-size: 14px;
			line-height: 1.5em;
			text-align: center;
			letter-spacing: 1px;
				  }
			
			
    