.hero {
    height: 100vh;
    background-color: #010101;
    
	/*&:before{width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: #000;
    z-index: 0;
    content: "";
    opacity: .8;}*/
	
	@media only screen and (max-width: 480px){display: flex;
		height:auto!important;
flex-direction: column;
background-position: -40vw center;
background-size: cover;}
	
    & img {
	height: calc(100% - 80px);
    width: auto;
    margin: 80px 0;
		
        /* transform: perspective(100vh) translateZ(50px); */
	@media only screen and (max-width: 480px){
		    position: relative;
			margin: 0 auto;
			right: auto;
			width: 140%;
			height: auto;
			transform: none;
			top: 60px;
			left: -10%;
		    padding: 10vh 0;
		}
    }
    & h1{
        position: absolute;
        top: 50%;
        left: 10%;
        z-index: 10;
        color: whitesmoke;
        transform: translateY(-50%);
        font-size: 3em;
    }
    & .btn-scroll {
        border: 2px solid rgba(255,255,255,0.5);
        border-radius: 16px;
        display: block;
        height: 50px;
        left: 50%;
        /* margin-left: -17px; */
        position: absolute;
        width: 30px;
        z-index: 10;
        cursor: pointer;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: 2em;
		@media only screen and (max-width: 480px){display:none;}
        & .key {
        
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-name: drop;
            animation-play-state: running;
            animation-timing-function: linear;
            background: #f5f5f5 none repeat scroll 0 0;
            border-radius: 10px;
            height: 10px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            top: 4px;
            width: 4px;
        }
    }
    & .price {
        font-size: 2em;
    }
    & .summary.entry-summary .entry-title {
        width: 100%;
    }
    
}

#navigation{
	& .secondary-cart{
		& a.cart-totals{
			color: #e8cf74;
			font-weight: 700;
			text-decoration: none;
			height: 35px;
			width: 71px;
			line-height: 40px;
			display: block;
			text-align: center;
			font-size: .75em;
			background: url(//www.caffeideal.com/wp-content/themes/ideal2020/images/cart_icon.svg);
			background-repeat: no-repeat;
			/*& span{@media only screen and (max-width: 480px){margin-left:7px;}}*/
		}
		& a.cart-totals.shaking {
			animation: shake 1.2s cubic-bezier(.36,.07,.19,.97) both;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000px;
			animation-iteration-count: infinite;
		}
		& a.cart-totals.addedtocartanim {
			animation: grow .6s cubic-bezier(.36,.07,.19,.97) both;
			backface-visibility: hidden;
			perspective: 1000px;
			transform-origin: center;
			animation-iteration-count: 1;
		}
		& a.cart-totals.removedfromcartanim {
			animation: shrink .6s cubic-bezier(.36,.07,.19,.97) both;
			backface-visibility: hidden;
			perspective: 1000px;
			transform-origin: center;
			animation-iteration-count: 1;
		}
	}
	
}

@keyframes drop {
  0% {
    opacity:0;
    top:5px
  }

  30% {
    opacity:1;
    top:10px
  }

  100% {
    opacity:0;
    top:25px
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-.1em, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(.2em, 0, 0) rotate(3deg);
  }

  30%, 50%, 70% {
    transform: translate3d(-.4em, 0, 0) rotate(-3deg);
  }

  40%, 60% {
    transform: translate3d(.4em, 0, 0);
  }
}
@keyframes grow {
  0% {
    transform:scale(1);
  }

  50% {
    transform:scale(1.4);
  }

  100% {
    transform:scale(1);
  }
}
@keyframes shrink {
  0% {
    transform:scale(1);
  }

  50% {
    transform:scale(.7);
  }

  100% {
    transform:scale(1);
  }
}



.summary.entry-summary form {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 240px;
	&.cart.cart-fixed{@media screen and (max-width: 480px){display:none;}}
	&  input {
	    width: 5em;
    height: 100%;
    color: #000;
    background-color: #fff;
    text-align: center;
    font-size: 1em;
    border: 2px solid #fff;
    padding-right: 8px;
    font-weight: 700;
    margin: 0;
    box-sizing: border-box;
		border-radius: 0px;
		&:focus{outline: none;}
	}
	& button {
    color: #fff;
    background-color: #000;
    border: 1px solid;
    width: 100%;
    padding: .5em;
    letter-spacing: 0.03em;
    transition: all .4s ease-out;
    font-size: 1em;
    cursor: pointer;
    font-weight: 700;
    text-shadow: none;
    text-align: center;
    height: 4em;
    box-sizing: border-box;
		&:hover {
        color: #fff;
    background: brown;
    border-color: #fff;
		}
	}
	& label{
	
    margin-bottom: 1em;
    display: flex;display: none;
    width: 100%;
    justify-content: space-between;
    align-items: center;  
		& span{color:whitesmoke}
	}
	& .quantity {
    display: flex;
	}
	
}

#navigation{
	& .secondary-cart{
		position: relative;
		right:70px;
		display: flex;
    	flex-direction: row;
		& #my_account_register_login {
			line-height: 35px;
			margin-right: 30px;
			color: #fff;
			text-decoration: none;
			font-size: .9em;
		}
		& #my_account_button{
			height: 36px;
			width: 71px;
			background: url(//www.caffeideal.com/wp-content/themes/ideal2020/images/my_account_icon.svg);
			background-repeat: no-repeat;
			text-indent: -999px;
			overflow: hidden;
			&.noaccess{opacity: .6;}
		}
	
		& .cart-dropdown {
			position: absolute;
			right: 0;
			top: 50px;
			width: 300px;
			background: #fff;
			border-top-right-radius: 20px;
			overflow: hidden;
			border-bottom-left-radius: 20px;
			
			
			@media screen and (max-width: 480px){width: 100vw;left: 0;right: 0;margin: 0;border-top-right-radius: 0;border-bottom-left-radius: 0;
				position: fixed;top: 60px; z-index: 9;}
			
			& h4 {
				display:none;
				font-size: .8em;
				color: #111;
				padding: 1em;
				background: #e8cf74;
				margin: 0 0 10px;
				font-weight: 700;
				letter-spacing: .03em;
				text-transform: uppercase;
   				}
			& .dropdown-cart-wrap{
				display: flex;
				flex-direction: row;
				& p{width:100%; text-align: center; color:#000;}
				& .dropdown-cart-left{
					width:40%;display: flex;align-items: center;
					& img{width: 80px;height: auto;margin: 5px 20px;}
					&.dropdown-cart-link {
						height:70px;width:50%;padding-left: 0;display: flex;align-items: center;justify-content: center;
						& a{
							text-decoration: none;
							color: #e8cf74;
							background: #333;
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;
							width: 80%;
							font-size: .7em;
							border-radius: .5em;
							transition: background .2s linear;
							line-height: 1em;
							padding: 1em 1em;
							box-sizing: border-box;
							&:hover{color: #e8cf74;background: #000;transition: background .2s linear;}
							}
						}
					}
				& .dropdown-cart-right{
					width: 60%;padding-right:20px;height: 100px;display: flex;flex-direction: column;justify-content: center;box-sizing: border-box;
					& h5{color: #333;font-weight: normal;padding: 0;margin: 0;font-size: .8em;}
					& p{margin: 0;
						padding: 0;
						font-size: .7em;
						text-align: left;
						line-height:1.3em;
						color: #8a8a8a;
						font-weight: normal;
						& strong{font-weight: normal}
						}
					&.dropdown-checkout-link {
						height:70px;width:50%;
						padding-left: 0;padding-right: 0;align-items: center;
						& a{
							text-decoration: none;
							color: #e8cf74;
							background: #333;
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;
							width: 80%;
							font-size: .7em;
							border-radius: .5em;
							transition: background .2s linear;
							line-height: 1em;
							padding: 1em 1em;
							box-sizing: border-box;
							&:hover{color: #e8cf74;background: #000;transition: background .2s linear;}
							}
						}
					}
				&.dropdown-cart-subtotal{
					
					height: 50px;box-sizing: border-box;overflow: hidden;
					border-top: 1px solid #ccc;
   					border-bottom: 1px solid #ccc;
					& .dropdown-cart-left{
						& h6{font-size:0.8em;font-weight: normal;}
					}
					& .dropdown-cart-total{width:100%;}
					& h6{margin: 0;
						padding: 0;
						color: #333;
						line-height: 50px;
						background: #fff;
						text-align: right;
						width: 90%;
						font-weight: normal;
						letter-spacing: normal;
						& span.amount{font-size: 1.8em;font-weight: normal;}
					}
					& .dropdown-cart-left{height:50px;}
					& .dropdown-cart-right{height:50px;}
					}
				
				}
			}
	
	}
}
	
	
	





